import { env } from '@/env'

import type { ResourcesConfig } from 'aws-amplify'

import packageJson from '../../package.json'

// MAGIC NUMBERS
export const ONE_MINUTE = 1000 * 60
export const FIVE_MINUTES = 1000 * 60 * 5
export const ONE_HOUR = 1000 * 60 * 60
export const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24 * 24

// ENVIRONMENT
export const isDEV = import.meta.env.DEV
export const isPROD = import.meta.env.PROD
export const isMOCK = env.VITE_USE_MOCK_DATA
export const appVersion = packageJson.version

// API
export const API_URL = `${env.VITE_AIR_API_URL}`

// AMPLIFY/COGNITO
export const amplifyConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			signUpVerificationMethod: 'code',
			userPoolId: env.VITE_AWS_USER_POOLS_ID,
			userPoolClientId: env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
			loginWith: {
				email: true,
				phone: false,
				username: true,
			},
		},
	},
}
